


  .student-detail {
    display: flex;
    align-items: flex-start;
  }
  
  .content2 {
    margin-left: 20%;
    padding: 20px;
    width: 80%;
    box-sizing: border-box;
  }



  
  .student-detail p {
    font-size: 16px;
    color: #555;
    margin: 10px 0;
  }
  
 
  .course-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;
  }
  
  .course-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    overflow: hidden;
  }
  
  .course-thumbnail2 {
    width: 400px;
    height: 200px;
    object-fit: contain;
  }
  
  .course-content {
    padding: 15px;
  }
  
  .course-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .course-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  

  .student-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }
  
  
  .course-buttons a {
    background-color: #4caf50;
    width: 35%;
  }
  
  .course-buttons a:last-child {
    background-color: #007bff;
    width: 25%;
  }
  
  .delete-button {
    background-color: #f44336;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #e53935;
  }
  
  @media (max-width: 768px) {
    .course-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .course-card {
      width: 100%;
    }
  }
  
  .alert {
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  text-align: center;
}

.alert.success {
  background-color: #d4edda;
  color: #155724;
}

.alert.error {
  background-color: #f8d7da;
  color: #721c24;
}


.heading2 {
    background-color: #333;
    color: #fff;
    
    padding: 10px;
    border-radius: 5px;
text-align: left;
margin-top: 20px;
margin-bottom: 20px;
  }
