/* CourseDetail.css */

/* Root container styling */
.course-detail-container {
    display: flex;
    height: 100vh;
  }
  
  /* Dark theme for the navbar */
  .course-detail-nav {
    background-color: #2c2c2c;
    color: #ffffff;
    width: 250px;
    padding: 20px;
    overflow-y: auto;
    height: 100%;
  }
  
  .course-detail-nav h4 h1 h2 {
    color: #ffffff;
    margin-bottom: 20px;
    font-size: 1.2em;
    font-weight: 600;
  }
  
  .course-detail-nav ul {
    list-style-type: none;
    padding: 0;
  }
  
  .course-detail-nav .chapter-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .course-detail-nav .chapter-item button {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    text-align: left;
    padding: 10px;
    /* width: 100%; */
    transition: background 0.3s ease;
  }
  
  .course-detail-nav .chapter-item button.active,
  .course-detail-nav .chapter-item button:hover {
    background-color: #444444;
  }
  
  .course-detail-nav .delete-chapter-button {
    background: none;
    border: none;
    color: #ff4c4c;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .course-detail-nav .rearrange-arrow {
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .course-detail-nav .add-chapter-button,
  .course-detail-nav .rearrange-button,
  .course-detail-nav .save-order-button ,
  .navigate-course-button{
    background-color: #444444;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 10px 20px;
    margin-top: 20px;
    width: 100%;
    transition: background 0.3s ease;
  }
  
  .course-detail-nav .add-chapter-button:hover,
  .course-detail-nav .rearrange-button:hover,
  .course-detail-nav .save-order-button:hover,
  .navigate-course-button:hover {
    background-color: #5c5c5c;
  }
  
  /* Light theme for the main content and forms */
  .course-detail-main {
    flex: 1;
    padding: 20px;
    background-color: #f4f4f4;
    color: #333333;
    overflow-y: auto;
  }
  
  .chapter-content form {
    width: 80%;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .chapter-content h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .chapter-content label {
    display: block;
    margin-bottom: 8px;
    color: #333333;
    font-weight: 600;
  }
  
  .chapter-content input[type="text"],
  .chapter-content input[type="file"],
  .chapter-content textarea,
  .chapter-content select {
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 1em;
    font-family: 'Arial', sans-serif;
  }
  
  .chapter-content textarea {
    height: 120px;
    resize: vertical;
  }
  
  .chapter-content button {
    background-color: #4caf50;
    width: 100%;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1em;
    transition: background 0.3s ease;
  }
  
  .chapter-content button:hover {
    background-color: #45a049;
  }
  
  .upload-progress {
    margin-top: 10px;
    color: #4caf50;
  }
  
  /* Modal styling */
  .ReactModal__Content {
    max-width: 500px;
    margin: auto;
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .ReactModal__Content h2 {
    margin-bottom: 20px;
    color: #333333;
    font-size: 1.5em;
  }
  
  .ReactModal__Content label {
    display: block;
    margin-bottom: 8px;
    color: #333333;
    font-weight: 600;
  }
  
  .ReactModal__Content select,
  .ReactModal__Content input[type="text"],
  .ReactModal__Content input[type="file"],
  .ReactModal__Content textarea {
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 1em;
    font-family: 'Arial', sans-serif;
  }
  
  .ReactModal__Content textarea {
    height: 120px;
    resize: vertical;
  }
  
  .ReactModal__Content button {
    background-color: #4caf50;
    border: none;
    color: #ffffff;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 1em;
    transition: background 0.3s ease;
    margin-right: 10px;
  }
  
  .ReactModal__Content button:hover {
    background-color: #45a049;
  }
  