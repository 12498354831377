/* VerticalNavbar.css */

.vertical-navbar {
    background-color: #2c2c2c;
    color: #ffffff;
    width: "20%";
    padding: 20px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
  }
  
  .vertical-navbar .nav-links {
    list-style-type: none;
    padding: 0;
  }
  
  .vertical-navbar .nav-links li {
    margin-bottom: 10px;
  }
  
  .vertical-navbar .nav-links a {
    color: #ffffff;
    text-decoration: none;
    display: block;
    padding: 10px;
    transition: background 0.3s ease;
  }
  
  .vertical-navbar .nav-links a:hover {
    background-color: #444444;
    border-radius: 5px;
  }
  
  .vertical-navbar .nav-links a.active {
    background-color: #444444;
    border-radius: 5px;
  }
  