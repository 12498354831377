.edit-course-screen {
    display: flex;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .top-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .edit-button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
  }
  
 
  
  .course-thumbnail3 {
    width: 400px;
    height: 200px;
    object-fit: contain;
    margin-right: 20px;
    border: 2px solid #ddd; /* Light gray border */
    border-radius: 8px; /* Optional: Rounded corners */
  }
  
  
  .course-content {
    flex: 1;
  }
  
  .course-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="number"] {
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button.cancel {
    background-color: #f44336;
  }
  