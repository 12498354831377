.course-screen {
    display: flex;
    background-color: #f5f5f5;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  

  
  .course-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .course-card {
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    overflow: hidden;
    width: 400px;  /* Adjusted width */
    height: fit-content; /* Increased height to accommodate buttons */
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  
  .course-thumbnail2 {
    width: 400px;
    height: 200px; /* Set image height */
    object-fit: contain; /* Ensure the image covers the area */
  }
  
  .course-content {
    padding: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
  }
  
  .course-title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
  }
  
  .course-menu {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .course-buttons {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
  }
  
  .course-buttons button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
    flex: 1;
    margin: 0 5px;
  }
  
  .course-buttons button:hover {
    background-color: #0056b3;
  }
  
  .course-buttons button.edit {
    background-color: #6c757d;
  }
  
  .course-buttons button.edit:hover {
    background-color: #5a6268;
  }
  
  .modal {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  .modal-overlay {
    background: rgba(0,0,0,0.5);
  }
  
  form label {
    display: block;
    margin-bottom: 10px;
  }
  
  form input[type="text"],
  form input[type="number"] {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  form button {
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: background-color 0.3s;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  
  form button.cancel {
    background-color: #6c757d;
  }
  
  form button.cancel:hover {
    background-color: #5a6268;
  }
  
 
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure space between items */
    margin-bottom: 20px;
  }
  
  .search-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 200px; /* Adjust width as needed */
  }
  
  .add-button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .add-button:hover {
    background-color: #45a049;
  }
  
  .heading2 {
    background-color: #333;
    color: #fff;
    
    padding: 10px;
    border-radius: 5px;
text-align: left;
margin-top: 20px;
margin-bottom: 20px;
  }


  /* CourseScreen.css */

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #4caf50;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
