.student-screen {
    display: flex;
  }
  
  .content {
    margin-left: 20%;
    padding: 20px;
    width: 80%;
    box-sizing: border-box;
  }
  
  .top-bar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  
  .add-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    top: 20px;
    right: 20px;
    
  }
  
  .add-button:hover {
    background-color: #0056b3;
  }
  
  .student-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .student-table th, .student-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .student-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  .view-more {
    padding: 5px 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .view-more:hover {
    background-color: #218838;
  }
  